<template>
    <DnAccordion
        ref="accordion"
        :items="items"
        class="base-accordion"
        :scroll-offset="isLargeScreen ? 90 : 70"
    >
        <template #title="{ item }">
            {{ item.title }}
        </template>

        <template #default="{ item }">
            <!-- eslint-disable vue/no-v-html -->
            <div
                class="base-accordion__content"
                v-html="item.content"
            />
            <!-- eslint-enable vue/no-v-html -->
        </template>

        <template #icon>
            <BaseIcon
                class="base-accordion__icon--closed"
                icon="plus"
            />
            <BaseIcon
                class="base-accordion__icon--open"
                icon="minus"
            />
        </template>
    </DnAccordion>
</template>

<script setup>
import { useMediaQuery } from '@vueuse/core';
import DnAccordion from '@digitalnatives/accordion';
import BaseIcon from '@/components/BaseIcon/BaseIcon.vue';

const accordion = ref(null);

const isLargeScreen = useMediaQuery('(min-width: 768px)');

defineProps({
    items: {
        type: Array,
        required: true,
        default() { return []; }
    }
});
</script>

<style src="./BaseAccordion.less" lang="less" />
